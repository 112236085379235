/* You can add global styles to this file, and also import other style files */
.xpo-AgGrid .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
  height: 28px !important;
}

.xpo-AgGrid {
  .ag-row-selected {
    background-color: lightcoral !important;

    .ag-column-hover {
      background-color: lightcoral !important;
    }
  }
}


app-cis-review-queue {
  .mat-drawer.xpo-TrayDrawer {
    min-width: 1200px;
  }

  .ag-horizontal-left-spacer {
    visibility: hidden;
  }
}
